.largeHeader {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;

}

.smallHeader {
  // float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.topRow {
  padding-top: 25px;
}

.titleContainer {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 120px);
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  height: 33.5px;
}

.statusHeader {
  text-align: right;
  font-style: italic;
  width: 120px;
}

// Outer container (everything but the top header)
.container {
  height: calc(100vh - 65px);
}

// Inner container (everything but the headers and the footer)
.listContainer {
  height: calc(100vh - 272px); // 272
}

// Inner container (everything but the headers)
.page {
  height: calc(100vh - 189px);
}

.row {
  display: flex;
  position: relative;
  height: 100%;
}

.col {
  position: relative;
  height: 100%;
  width: 100%;
}

.colList {
  position: relative;
  height: 100%;
  width: 55%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.colMap {
  position: relative;
  height: 100%;
  width: 45%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
  background-color: #F6F6F6;;
}

.scroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.slimScroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.mapPart {
  height: 100%;
  background-color: #F6F6F6;;
}

.mapHelpCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
}

.editPencil {
  cursor: pointer;
  padding-left: 10px;
  display: inline-flex;
  margin-bottom: 0px;
}

// Locations
.locations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.locationButton {
  width: calc(50% - 5px);
}

.mobileLocationButton {
  width: 100%;
}

// Uptime
.uptimeItem {
  display: inline-block;
  position: relative;
  height: 80px;
  width: 80px;
  background-color: var(--tooltip-color);
  justify-content: space-between;
  margin-bottom: 6px;
  margin-right: 6px;
}

.uptimeItemTitle {
  display: block;
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  top: 2px;
  left: 4px;
  width: 100%;
  letter-spacing: normal;
  color: white;
}

.uptimeItemValue {
  display: block;
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 80px;
  width: 100%;
  letter-spacing: normal;
  color: white;
  text-align: center;
}

.block {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  padding: 10px 20px;
  flex-grow: 1;
  margin-left: "10px";
  margin-right: "10px";
}

.blockTitle {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
  margin-top: 10px;
}

.blockDescription {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-top: 10px;
  
  ul {
    margin-bottom: 0px;

    li {
      margin-bottom: 5px;
      list-style-type: disc;
    }
  }
}


.blockFootnote {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-top: 5px;
  text-align: center;
}


