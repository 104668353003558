.dropdownWrapper {
  margin-top: 19px;
  display: inline-grid;

  .labelContainer {
    display: flex;
    justify-content: flex-start;
  }

  label {
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  select::-ms-expand {
    display: none;
  }
}

.dropdownWrapperNoLabel {
  display: inline-grid;

  label {
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  select::-ms-expand {
    display: none;
  }
}

.dropdownSelector {
  border-radius: 5px;
  border: solid 1px #D8D8D8;
  width: 100%;
  overflow: hidden;
  background: url("./caret3.png") 96% / 25px no-repeat #fff;
  @media (max-width: 360px) {
    width: 160px;
  }
  margin-top: 5px;
  cursor: pointer;
}

// if data-hidewrapper is true, hide the wrapper
.dropdownSelector[data-hidewrapper=true] {
  border-radius: 5px;
  border: 0;
  width: 100%;
  overflow: hidden;
  background: url("./caret3_grey.png") 96% / 25px no-repeat #fff;
  @media (max-width: 360px) {
    width: 160px;
  }
  margin-top: 0;
  cursor: pointer;
}

.dropdownSelector[data-disabled=true] {
  background: url("./caret3_grey.png") 96% / 25px no-repeat #eee;
}

.dropdownSelector[data-nomargintop=true] {
  margin-top: 0;
}

.dropdownSelector select {
  font-family: Source Sans Pro;
  font-size: 17px;
  padding: 8px 32px 9px 12px;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 360px;
  width: 100%;
  color: #444;
  @media (max-width: 360px) {
    max-width: 120px;
  }
  cursor: pointer;
}

.dropdownSelector[data-hidewrapper=true] select {
  color: #666;
  padding: 9px 32px 10px 12px;
  cursor: pointer;
}

.dropdownSelector select:focus {
  outline: none;
}

.info {
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
}
