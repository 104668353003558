.background {
  cursor: pointer;
  background-color: white;
  border-color: white;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  padding: 10px;
  width: calc(100% - 44px);
}

.background:hover {
  background-color: #ccc;
  border-color: #ccc;
}

.selectedBackground {
  cursor: pointer;
  background-color: #ccc;
  border-color: #ccc;
  border-width: 2px;
  border-radius: 5px;
  border-style: solid;
  padding: 10px;
  width: calc(100% - 44px);
}

.newBackground {
  border-width: 2px;
  border-color: #ccc;
  border-radius: 5px;
  border-style: dashed;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  width: calc(100% - 44px);
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.content {
  display: flex;
  width: 100%;
  height: 48px;
}

.left {
  flex: 1;
  min-width: 50px;
  overflow: hidden;
  float: left;
}

.right {
  padding-left: 10px;
  min-width: 50px;
  overflow: hidden;
  white-space: nowrap;
  float: right;  
  text-overflow: ellipsis;
  display: flex;
  justify-content: flex-end;
}

.type {
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  height: 18px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.title {
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyTitle {
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  color: #aaa;
  min-width: 50px;
}

.background:hover .emptyTitle,
.selectedBackground .emptyTitle {
  color: #888;
}

.text {
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  float: right;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.emptyText {
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
  float: right;
  color: #aaa;
  display: inline;
}

.rowContainer {
  display: flex;
  margin-bottom: 10px;
}

.arrowContainer {
  position: relative;
  height: 72px;
}

.arrowUp {
  cursor: pointer;
  position: absolute;
  top: 18%;
}

.arrowDown {
  cursor: pointer;
  position: absolute;
  bottom: 18%;
}

.arrowPlaceholder {
  width: 20px;
}