.largeHeader {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.smallHeader {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.topRow {
  padding-top: 25px;
}

.header {
  display: flex;
  justify-content: space-between;
  padding-top: 30px;
  height: 33.5px;
}

.statusHeader {
  text-align: right;
  font-style: italic;
}

// Outer container (everything but the top header)
.container {
  height: calc(100vh - 65px);
}

// Inner container (everything but the headers and the footer)
.listContainer {
  height: calc(100vh - 272px); // 272
}

// Inner container (everything but the headers)
.page {
  height: calc(100vh - 189px);
}

// Locations
.locations {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.locationButton {
  width: calc(50% - 5px);
}

.mobileLocationButton {
  width: 100%;
}

.buttonContainer {
  width: 100%;
}

.row {
  display: flex;
  position: relative;
  height: 100%;
}

.col {
  position: relative;
  height: 100%;
  width: 100%;
}

.colList {
  position: relative;
  height: 100%;
  width: 60%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.colMap {
  position: relative;
  height: 100%;
  width: 40%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.scroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.slimScroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.mapPart {
  height: 100%;
  background-color: #F6F6F6;;
}

.modeButton {
  position: absolute;
  top: 14px;
  right: 14px;
}

.mapHelp { 
  position: absolute;
  right: 5px;
  top: 5px;
  width: 170px;
  text-align: center; 
  background-color: white;
}

.mapHelp33 { 
  position: absolute;
  right: 5px;
  top: 5px;
  width: 170px;
  text-align: center;
  background-color: white;
}

.editPencil {
  cursor: pointer;
  padding-left: 10px;
  display: inline-flex;
  margin-bottom: 0px;
}

.mapHelpCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #666;
}

// Uptime
.uptimeItem {
  display: inline-block;
  position: relative;
  height: 80px;
  width: 80px;
  background-color: var(--tooltip-color);
  justify-content: space-between;
  margin-bottom: 6px;
  margin-right: 6px;
}

.uptimeItemTitle {
  display: block;
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 12px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  top: 2px;
  left: 4px;
  width: 100%;
  letter-spacing: normal;
  color: white;
}

.uptimeItemValue {
  display: block;
  position: absolute;
  font-family: Source Sans Pro;
  font-size: 20px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 80px;
  width: 100%;
  letter-spacing: normal;
  color: white;
  text-align: center;
}