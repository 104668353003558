.outerScroll {
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
}

.innerContainer {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;

  // Smaller margins for smaller screens
  @media (max-width: 768px) {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;

  // Smaller margins for smaller screens
  @media (max-width: 768px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container ul {
  margin-block-start: 0.8em;
  margin-block-end: 1.5em;
}

.container ul ul {
  margin-block-start: 0.5em;
  margin-block-end: 1em;
}

.container li {
  font-size: 16px;
  list-style-type: disc;
  margin-block-start: 0.5em;
  margin-block-end: 0.5em;
}

.container ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.container ol li {
  list-style-type: inherit;
}

.footnote {
  text-align: end;
  font-size: 15px;
  color: #444;
  margin-top: 50px;
}

.container h2, .container h3, .container h4 {
  font-weight: 600 !important;
}

.container p {
  font-size: 16px;
  margin-block-start: 0.3em;
}