.largeHeader {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.smallHeader {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.outerScroll {
  height: 100%;
  overflow: auto;
}

.outerEditScroll {
  height: calc(100% - 85px);
  overflow: auto;
}

.topRow {
  display: flex;
  padding-top: 25px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 25px;
  padding-bottom: 20px;

  .searchCol {
    width: 400px;
    max-width: 100%;
  }

  .buttonCol {
    max-width: 100%;
  }
}

.icon {
  float: left;
  padding-right: 20px;
}

.title {
  font-family: Source Sans Pro;
  font-size: 35px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
}

.subtitle {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0c0f26;
}

.rowContainer {
  border: solid 1px #dbdbdb;
  border-radius: 9px;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .arrowIcon {
    margin-top: 50px;
  }
}

.tableLineHeight {
  padding-top: 7px;
  line-height: 1.5em;
}

.selectionHeader {
  h2 {
    font-size: 42px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  h3 {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  margin-top: 40px;
  text-align: center;
}

.page {
  height: calc(100vh - 65px);
}

.listContainer {
  position: relative;
  height: 100%;
  width: 50%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
  z-index: 2;
}

.listContainerMobile {
  height: calc(100vh - 229px);
  z-index: 2;
}

.row {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.col {
  position: relative;
  height: 100%;
  width: 100%;
}

.scroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.slimScroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 10px;
  padding-right: 10px;

  input {
    background-color: white; //#F6F6F6;
  }

  .search {
    background-color: #F6F6F6;
  }
}

.sideBar {
  height: 100%;
  width: 50%;
  background-color: #F6F6F6;

  input {
    background-color: white; //#F6F6F6;
  }

  .search {
    background-color: #F6F6F6;
  }
}

.mainContainer {
  height: calc(100vh - 272px);
}

.mainContainerMobile {
  height: calc(100vh - 229px);
}

.mainContainerHidden {
  height: calc(100vh - 188px);
}

.mainContainerMobileHidden {
  height: calc(100vh - 145px);
}