.ReactTable {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  padding-top: 10px;
  padding-bottom: 20px;
}

.ReactTable * {
  box-sizing: border-box
}

.arrow {
  margin-top: 10px;
  height: 32px;
  width: 32px;
  background-image: url('./ic-keyboard-arrow-right.svg');
  float: right;
  background-repeat: no-repeat;
  background-position: center;
}

.checkboxContainer {
  display: block;
  position: relative;
  margin-top: 13px;
  margin-bottom: 12px;
  cursor: pointer; 
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.checkboxHeaderContainer {
  margin-top: 18px;
  height: 25px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 24px;
}

.checkmark {
  position: absolute;
  top: 0;
  left: 6px;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: solid 1px #1C4D82;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input~.checkmark {
  background-color: #F1F2F3;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked~.checkmark {
  background-color: #1C4D82;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabledCheckboxContainer {
  display: block;
  position: relative;
  margin-top: 13px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.disabledCheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 24px;
}

.disabledCheckmark {
  position: absolute;
  top: 0;
  left: 6px;
  width: 24px;
  height: 24px;
  background-color: #ffffff;
  border: solid 1px #6b7073;
  border-radius: 3px;
  cursor: not-allowed;
}

/* When the checkbox is checked, add a blue background */
.disabledCheckboxContainer input:checked~.disabledCheckmark {
  background-color: #6b7073;
}

/* Create the checkmark/indicator (hidden when not checked) */
.disabledCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.disabledCheckboxContainer input:checked~.disabledCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.disabledCheckboxContainer .disabledCheckmark:after {
  left: 7px;
  top: 2px;
  width: 6px;
  height: 12px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

/* Style a radio button */
.radioContainer {
  display: block;
  position: relative;
  margin-top: 13px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.radioHeaderContainer {
  margin-top: 18px;
  height: 25px;
}

/* Hide the browser's default radio button */
.radioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 20px;
}

.radioMark {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 20px;
  height: 20px;
  background-color: #ffffff;
  border: solid 1px #1C4D82;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioContainer:hover input~.radioMark {
  background-color: #F1F2F3;
}

/* When the radio button is checked, add a blue background */
.radioContainer input:checked~.radioMark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioMark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (the dot/circle) when checked */
.radioContainer input:checked~.radioMark:after {
  display: block;
}

/* Style the indicator (the dot/circle) */
.radioContainer .radioMark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #1C4D82;
}

.disabledRadioContainer {
  display: block;
  position: relative;
  margin-top: 13px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 20px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default radio button */
.disabledRadioContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 20px;
}

.disabledRadioMark {
  position: absolute;
  top: 2px;
  left: 7px;
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: solid 1px #6b7073;
  border-radius: 50%;
  cursor: not-allowed;
}

/* When the radio button is checked, add a blue background */
.disabledRadioContainer input:checked~.disabledRadioMark {
  background-color: #fff;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.disabledRadioMark:after {
  content: "";
  position: absolute;
  display: none;
}
/* Show the indicator (the dot/circle) when checked */
.disabledRadioContainer input:checked~.disabledRadioMark:after {
  display: block;
}

/* Style the indicator (the dot/circle) */
.disabledRadioContainer input:checked~.disabledRadioMark:after {
  top: 3px;
  left: 3px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #6b7073;
}


.ReactTable .rt-table {
  -webkit-box-flex: 1;
  -ms-flex: auto 1;
  flex: auto 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
  border-collapse: collapse;
  overflow: auto
}

.ReactTable .rt-thead {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 55px;
}

.ReactTable.-noHeader .-header {
  display: none;
}

.ReactTable.-row-clickable .rt-tr-group {
  cursor: pointer;
}

.ReactTable .rt-thead.-headerGroups {
  background: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05)
}

.ReactTable .rt-thead.-filters {
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline: none
}

.ReactTable .rt-thead.-header {
  /* box-shadow: inset 0 -1px 0 0 #DBDBDB; */
}

.ReactTable .rt-thead .rt-th,
.ReactTable .rt-thead .rt-td {
  /* padding: 5px 5px; */
  position: relative;
  /* transition: box-shadow .3s cubic-bezier(.175, .885, .32, 1.275); */
  /* box-shadow: inset 0 0 0 0 transparent; */

  
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  color: #111;
  font-weight: 600;
}

.ReactTable .rt-thead .rt-th.-sort-asc,
.ReactTable .rt-thead .rt-td.-sort-asc {
  float: right;
  background-image: url('./arrow-up.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.ReactTable .rt-thead .rt-th.-sort-desc,
.ReactTable .rt-thead .rt-td.-sort-desc {
  float: right;
  background-image: url('./arrow-down.svg');
  background-repeat: no-repeat;
  background-position: right;
}

.ReactTable .rt-thead .rt-th.-cursor-pointer,
.ReactTable .rt-thead .rt-td.-cursor-pointer {
  cursor: pointer
}

.ReactTable .rt-thead .rt-th:last-child,
.ReactTable .rt-thead .rt-td:last-child {
  border-right: 0
}

.ReactTable .rt-thead .rt-resizable-header {
  overflow: visible;
}

.ReactTable .rt-thead .rt-resizable-header:last-child {
  overflow: hidden
}

.ReactTable .rt-thead .rt-resizable-header-content {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 18px;
  height: 55px;
}

.ReactTable .rt-thead .rt-header-pivot:after,
.ReactTable .rt-thead .rt-header-pivot:before {
  left: 100%;
  top: 50%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none
}

.ReactTable .rt-thead .rt-header-pivot:after {
  border-color: rgba(255, 255, 255, 0);
  border-left-color: #fff;
  border-width: 8px;
  margin-top: -8px
}

.ReactTable .rt-thead .rt-header-pivot:before {
  border-color: rgba(102, 102, 102, 0);
  border-left-color: #f7f7f7;
  border-width: 10px;
  margin-top: -10px
}

.ReactTable .rt-tbody {
  -webkit-box-flex: 99999;
  -ms-flex: 99999 1 auto;
  flex: 99999 1 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  /* overflow: auto; */
}

.ReactTable .rt-tbody .rt-tr-group {
  line-height: 50px;
}

.ReactTable.noShadow .rt-tbody .rt-tr-group {
  box-shadow: none;
  line-height: 50px;
}

.ReactTable .rt-tbody .rt-tr-group:last-child {
  border-bottom: 0
}

.ReactTable .rt-tbody .rt-td {
  min-height: 50px;
}

.ReactTable.setMaxHeigth .rt-tbody .rt-td {
  max-height: 50px;
}

.ReactTable .rt-tbody .rt-td:last-child {
  border-right: 0
}

.ReactTable .rt-tbody .rt-expandable {
  cursor: pointer
}

.ReactTable .rt-tr-group {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
}

.ReactTable .rt-tr {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.ReactTable .rt-th,
.ReactTable .rt-td {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 0px;
  flex: 1 0 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 7px 5px;
  overflow: hidden;
  transition: .3s ease;
  transition-property: width, min-width, padding, opacity;
  padding: 0px 5px;
}

.ReactTable .rt-th {
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
}

.ReactTable .rt-th.-hidden,
.ReactTable .rt-td.-hidden {
  width: 0 !important;
  min-width: 0 !important;
  padding: 0 !important;
  border: 0 !important;
  opacity: 0 !important
}

.ReactTable .rt-expander {
  display: inline-block;
  position: relative;
  margin: 0;
  color: transparent;
  margin: 0 10px;
}

.ReactTable .rt-expander:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%) rotate(-90deg);
  transform: translate(-50%, -50%) rotate(-90deg);
  border-left: 5.04px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.8);
  transition: all .3s cubic-bezier(.175, .885, .32, 1.275);
  cursor: pointer
}

.ReactTable .rt-expander.-open:after {
  -webkit-transform: translate(-50%, -50%) rotate(0);
  transform: translate(-50%, -50%) rotate(0)
}

.ReactTable .rt-resizer {
  display: inline-block;
  position: absolute;
  width: 36px;
  top: 0;
  bottom: 0;
  right: -18px;
  cursor: col-resize;
  z-index: 10
}

.ReactTable .rt-tfoot {
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.15);
}

.ReactTable .rt-tfoot .rt-td:last-child {
  border-right: 0
}

.ReactTable.-striped .rt-tr.-odd {
  background: rgba(0, 0, 0, 0.03)
}

.ReactTable.-highlight .rt-tbody .rt-tr:not(.-padRow):hover {
  background: rgba(0, 0, 0, 0.05)
}

.ReactTable .-pagination {
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border-radius: 7px;
  border: solid 1px #DBDBDB;
  margin-top: 10px;
}

.ReactTable .-pagination input,
.ReactTable .-pagination select {
  border: 1px solid #DBDBDB;
  background: #fff;
  padding: 5px 7px;
  font-size: inherit;
  border-radius: 3px;
  font-weight: 400;
  outline: none
}

.ReactTable .-pagination .-btn {
  font-family: Source Sans Pro;
  /* color: #1C4D82; */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  background: none;
  padding: 6px;
  font-size: 1em;
  transition: all .1s ease;
  cursor: pointer;
  outline: none;
}

.ReactTable .-pagination .-btn[disabled] {
  opacity: .8;
  cursor: default
}

.ReactTable .-pagination .-btn:not([disabled]):hover {
  background: rgba(0, 0, 0, 0.3);
}

.ReactTable .-pagination .-previous,
.ReactTable .-pagination .-next {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center
}

.ReactTable .-pagination .-center {
  -webkit-box-flex: 1.5;
  -ms-flex: 1.5;
  flex: 1.5;
  text-align: center;
  margin-bottom: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  border-left: solid 1px #DBDBDB;
  border-right: solid 1px #DBDBDB;
}

.ReactTable .-pagination .-pageInfo {
  display: inline-block;
  margin: 3px 10px;
  white-space: nowrap;
}

.ReactTable .-pagination .-pageJump {
  display: inline-block;
  margin-left: 5px;
  margin-right: 5px;
}

.ReactTable .-pagination .-pageJump input {
  width: 60px;
  text-align: center
}

.ReactTable .-pagination .-pageSizeOptions {
  margin: 3px 10px
}

.rt-td {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

.ReactTable .rt-resizing .rt-th,
.ReactTable .rt-resizing .rt-td {
  transition: none !important;
  cursor: col-resize;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none
}

/* Minimalist */
.ReactTable.-minimalist .rt-tr-group:last-child {
  box-shadow: none;
}

.ReactTable.-minimalist {
  box-shadow: inset 0px 1px 0px 0px #dbdbdb;
  padding-top: 0;
  padding-bottom: 0;
}

.ReactTable.-no-header {
  box-shadow: none;
}

.ReactTable.-minimalist .rt-th, .ReactTable.-minimalist .rt-td {
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  white-space: normal;
}

.ReactTable.-minimalist .rt-tbody .rt-tr-group {
  line-height: 20px;
}

.ReactTable.-minimalist .arrow {
  margin-top: 0;
}

.ReactTable.-minimalist .rt-td.pull-right {
  float: right;
  justify-content: end;
}

/* No data and loading classes */
.ReactTable .rt-tbody .rt-tr-group-noData {
  line-height: 50px;
}

.ReactTable .rt-tbody .rt-td-noData {
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
}

.ReactTable.-minimalist .rt-tbody .rt-td-noData {
  box-shadow: none;
}

.ReactTable .rt-noData {
  display: block;
  z-index: 0;
  pointer-events: none;
  text-align: center;
  color: rgba(0, 0, 0, 0.5);
}

/* No header */
.ReactTable.-noHeader {
  box-shadow: none;
}

.ReactTable.-noHeader .rt-thead {
  display: none
}

.ReactTable.-noSidePadding .rt-th,
.ReactTable.-noSidePadding .rt-td {
  padding: 0;
  /* padding-left: 0;
  padding-right: 10px; */
  /* margin-left: -5px; */
}

.ReactTable .rt-th {
  /* box-shadow: inset 0 -1px 0 0 #aaa; */
}

.ReactTable.-darkSeparators .rt-tr-group {
  /* box-shadow: inset 0 -1px 0 0 #888; */
  background-color: #eee;
}