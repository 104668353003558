// default styling is to align with parent div and float a bit above input bottom border
// override as necessary
.icon {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}