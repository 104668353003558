.container {
  display: inline-flex;
  cursor: pointer;
  //box-shadow: 0 0 1px rgba(0, 0, 0, 0.8);
  border-radius: 4px;
  border-color: #bbb;
  border-width: 1px;
  border-style: solid;
}

.container[data-light-bg="true"]  {
  border-color: #ddd;
}

.container[data-vertical="true"]  {
  display: block;
}

.container[data-vertical="false"]  {
  height: 33px;
}

.container .button {
  background-color: white;
  padding: 7px 10px;
  color: #444;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  text-align: center;
  white-space: nowrap;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.container .button[data-checked="true"] {
  color: white;
}

.container .button[data-disabled="true"] {
  color: #bbb;
}

.container .button[data-checked="true"][data-color="blue"] {
  background-color: #1C4D82;
}

.container .button[data-checked="true"][data-color="green"] {
  background-color: #26BE3E;
}

.container .button[data-checked="true"][data-color="red"] {
  background-color: darkred;
}

.container .button[data-left="true"] {
  border-radius: 4px 0px 0px 4px;
}

.container .button[data-right="true"] {
  border-radius: 0px 4px 4px 0px;
}

.container .button[data-left="true"][data-right="true"] {
  border-radius: 4px 4px 4px 4px;
}

.container[data-vertical="true"] .button[data-left="true"] {
  border-radius: 4px 4px 0px 0px;
}

.container[data-vertical="false"] .button[data-left="false"] {
  border-left: rgba(0,0,0,.2);
  border-left-width: 1px;
  border-left-style: solid;
}

.container[data-vertical="true"] .button[data-left="false"] {
  border-top: rgba(0,0,0,.2);
  border-top-width: 1px;
  border-top-style: solid;
}

.container[data-vertical="true"] .button[data-right="true"] {
  border-radius: 0px 0px 4px 4px;
}

.container[data-vertical="false"] .button[data-minwidth="true"] {
  min-width: 20px;
}

.title {
  display: block;
  margin-bottom: 7px;
  font-size: 18px;
  color: #222222;
}

.label {
  display: block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
  margin-bottom: 5px;
}