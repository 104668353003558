.largeHeader {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
}

.smallHeader {
  float: left;
  padding-left: 10px;
  padding-right: 10px;
}

.outerScroll {
  height: 100%;
  overflow: auto;
}

.outerEditScroll {
  height: calc(100% - 85px);
  overflow: auto;
}

.topRow {
  padding-top: 25px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 25px;
  padding-bottom: 20px;
  align-items: center;

  .searchCol {
    width: 400px;
    max-width: 100%;
  }

  .buttonCol {
    max-width: 100%;
  }
}

.headerRowMobile {
  padding-top: 25px;
  padding-bottom: 20px;
}

.icon {
  float: left;
  padding-right: 20px;
}

.title {
  font-family: Source Sans Pro;
  font-size: 35px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
}

.subtitle {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0c0f26;
}

.rowContainer {
  border: solid 1px #dbdbdb;
  border-radius: 9px;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .arrowIcon {
    margin-top: 50px;
  }
}

.roomMapButton {
  display: inline-block;
  position: absolute;
  right: 20px;
  margin-top: 20px;

  svg path {
    stroke-width: 0;
  }
}

.createServiceContainer {
  .checkBox {
    margin-top: 20px;
  }
  .inputGroup {
    margin-top: 20px;
    label {
      font-weight: 700;
    }
    input {
      outline: 0;
      border-width: 0 0 2px;
      border-color: #dbdbdb;
      margin-top: 10px;
      width: 100%;
    }
    [required]::-webkit-input-placeholder {
        color:    #f00;
    }
    textarea {
      outline: 0;
      border-width: 1px 1px 2px;
      border-color: #dbdbdb;
      margin-top: 10px;
      width: 100%;
      resize: vertical;
      min-height: 50px;
    }
  }
  .tileContainer {
    width: 100%;
    background: #f1f2f3;
    .tile {
      padding: 20px;
    }
  }
  .underline {
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #dbdbdb;
  }

  .logoContainer {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    .logoCircle {
      width: 64px;
      height: 64px;
      line-height: 64px;
      background-color: #dbdbdb;
      border: solid 1px #dbdbdb;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }
    .logoCircleImg {
      width: 64px;
      height: 64px;
      line-height: 64px;
      background-color: white;
      border: solid 1px white;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }
    .addButton {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: solid 1px #1C4D82;
      line-height: 24px;
      text-align: center;
      background-color: #1C4D82;
      position: absolute;
      z-index: 0;
      color: white;
      top: 40px;
      left: 60px;
      cursor: pointer;
    }

    .fileInput {
      display: none;
    }
  }
  .urlPreview {
    border-radius: 7px;
    border: solid 1px #19aaf8;
    width: 100%;
    height: 98%;
    div {
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    div iframe {
      height: 80%;
      left: 10%;
      position: absolute;
      top: 10%;
      width: 80%;
    }
  }

  .previewContainer {
    background-color: #f1f2f3;
    border: 1px solid #dbdbdb;
    height: 250px;
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 75%;
    position: relative;

    .footer {
      font-family: Source Sans Pro;
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1C4D82;
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate(-50%, 0);
      cursor: pointer;
    }
  }

  .editor {
    min-height: 462px;
  }
}

.tableLineHeight {
  padding-top: 7px;
  line-height: 1.5em;
}

.noGatewayText {
  display: block;
  text-align: center;
  color: #444;
  margin-top: 50px;
}

.selectionHeader {
  h2 {
    font-size: 42px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  h3 {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  margin-top: 40px;
  text-align: center;
}

.editPencil {
  cursor: pointer;
  padding-left: 10px;
  display: inline-flex;
  margin-bottom: 0px;
}

.page {
  height: calc(100vh - 65px);
}

.listContainer {
  height: calc(100vh - 272px);
}

.listContainerPreventClicks {
  height: calc(100vh - 272px);
  pointer-events: none;
}

.listContainerMobile {
  height: calc(100vh - 229px);
}

.listContainerMobilePreventClicks {
  height: calc(100vh - 229px);
  pointer-events: none;
}

.listContainerHidden {
  height: calc(100vh - 188px);
}

.listContainerHiddenPreventClicks {
  height: calc(100vh - 188px);
  pointer-events: none;
}

.listContainerHiddenMobile {
  height: calc(100vh - 145px);
}

.listContainerHiddenMobilePreventClicks {
  height: calc(100vh - 145px);
  pointer-events: none;
}

.mapContainer {
  height: calc(100vh - 272px);
  position: absolute;
}

.mapContainerHidden {
  height: calc(100vh - 188px);
  position: absolute;
}

.fullPageContainer {
  height: calc(100vh - 65px);
}

.editContainer {
  height: calc(100vh - 150px);
}

.row {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.col {
  position: relative;
  height: 100%;
  width: 100%;
}

.col50 {
  position: relative;
  height: 100%;
  width: 60%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.col33 {
  position: relative;
  height: 100%;
  width: 33.33%;
  width: calc(100% / 3);
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.colDash {
  height: 100%;
  width: 100%;
  background-color: #F6F6F6;
}

.scroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 60px;
  padding-right: 60px;
}

.slimScroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.mapPart {
  height: calc(100% - 2em - 27px);
  background-color: #F6F6F6;
}

.mapPart40 {
  height: 100%;
  width: 40%;
  background-color: #F6F6F6;
}

.mapHelp { 
  position: absolute;
  right: 5px;
  top: 65px;
  width: 170px;
  text-align: center; 
  background-color: white;
}

.mapHelp33 { 
  position: absolute;
  right: 5px;
  top: 65px;
  width: 170px;
  text-align: center;
  background-color: white;
}

.mapHeader {
  padding-left: 60px;
  padding-right: 60px;
}

.modeButton {
  position: absolute;
  top: 14px;
  right: 14px;
}

.editModeButton {
  position: absolute;
  top: 54px;
  right: 14px;
}

.segmentControllPadding { 
  margin-top: 20px;
  display: inline-block;
}