.background {
  background-color: #eee;
  border-color: #eee;
  border-width: 2px;
  border-radius: 10px;
  border-style: solid;
  padding-bottom: 0.1px;
  width: calc(100% - 4px);
}

.background[data-show-arrows=true] {
  width: calc(100% - 24px);
}

.background[data-hover=true][data-disable-hover=false] {
  background-color: #ddd;
  border-color: #ddd;
}

.selectedBackground {
  background-color: #ddd;
  border-color: #ddd;
  border-width: 2px;
  border-radius: 10px;
  border-style: solid;
  padding-bottom: 0.1px;
  width: calc(100% - 4px);
}

.selectedBackground[data-show-arrows=true] {
  width: calc(100% - 24px);
}

.newBackground {
  background-color: #f0f0f0;
  border-color: rgb(204, 204, 204);
  border-width: 2px;
  border-radius: 10px;
  border-style: dashed;
  padding-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 20px;
  width: calc(100% - 4px);
}

.newBackground[data-show-arrows=true] {
  width: calc(100% - 24px);
}

.headerContainer {
  cursor: pointer;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.header {
  font-size: 17px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
}

.header[data-no-header=true] {
  color: #aaa;
}

.background[data-hover=true][data-disable-hover=false] .header[data-no-header=true],
.selectedBackground .header[data-no-header=true] {
  color: #888;
}

.buttonContainer {
  display: flex;
  justify-content: center;
}

.sectionContainer {
  display: flex;
  margin-bottom: 40px
}

.rowsContainer {
  padding-left: 10px;
  padding-right: 10px;
}

.arrowContainer {
  position: relative;
  height: 72px;
}

.arrowUp {
  cursor: pointer;
  position: absolute;
  top: 18%;
}

.arrowDown {
  cursor: pointer;
  position: absolute;
  bottom: 18%;
}

.arrowPlaceholder {
  width: 20px;
}