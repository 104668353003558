.inputGroup {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  text-align: left;

  label {
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    color: #000;
  }

  .colorBox {
    box-sizing: border-box;
    border-radius: 4px;
    margin-top: 5px;
    margin-bottom: 1px;
    width: 60px;
    height: 26px;
  }

  .colorBox:disabled {
    background: none;
  }

  [required]::-webkit-input-placeholder {
    color: #6b7073;
  }
}

.inputWrapper {
  position: relative;
}

.info {
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
}

// .cover {
//   position: fixed;
//   top: 0px;
//   right: 0px;
//   bottom: 0px;
//   left: 0px;
//   z-index: 998;
//   // pointer-events: none;
// }

.popover {
  position: absolute;
  z-index: 999;
}