.slimContainer {
  position: relative;

  %button {
    cursor: pointer;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-size: 18px;
    width: 100%;
    border-radius: 7px;
    display: block;
    position: relative;
    text-decoration: none;
    text-align: center;
  }

  .buttonPadding {
    padding: 8px;
  }

  button:disabled,
  button[disabled],
  button[disabled]:hover {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  button[data-singleline=true] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blue {
    @extend %button;
    border: 0;
    background-color: #1C4D82;
    color: #ffffff;
  }

  .blue:hover {
    background-color: #215997;
  }

  .blue:active:hover {
    background-color: #2566ac;
    color: #dddddd;
  }

  .blue:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  .white {
    @extend %button;
    border: solid 1px #1C4D82;
    color: #1C4D82;
    background-color: #ffffff;
  }

  .white:hover {
    background-color: #f6f6f6;
  }

  .white:active:hover {
    background-color: #eee;
    border: solid 1px #777;
    color: #777;
  }

  .white:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
    border: 0px;
  }

  .clear {
    @extend %button;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    border: 0;
    color: #1C4D82;
    background: none;
  }

  .clear:hover {
    color: #0063a8;
  }

  .clear:active:hover {
    color: #777;
  }

  .clear:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }
 
  .red {
    @extend %button;
    background-color: #c12d2a;
    border: 0;
    color: #ffffff;
  }

  .red:hover {
    background-color: #e13232;
  }

  .red:active:hover {
    background-color: #d33232;
    color: #dddddd;
  }

  .red:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  // button[data-loading=true] {
  //   background: #888;
  //   cursor: not-allowed;

  //   &:hover {
  //     background: #888;
  //   }
  // }
}

.buttonContainer {
  position: relative;

  %button {
    cursor: pointer;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-size: 18px;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 7px;
    display: block;
    position: relative;
    text-decoration: none;
    text-align: center;
  }

  .buttonPadding {
    padding: 15px 10px;
  }

  button:disabled,
  button[disabled],
  button[disabled]:hover {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  button[data-singleline=true] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .blue {
    @extend %button;
    border: 0;
    background-color: #1C4D82;
    color: #ffffff;
  }

  .blue:hover {
    background-color: #215997;
  }

  .blue:active:hover {
    background-color: #2566ac;
    color: #dddddd;
  }

  .blue:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  .white {
    @extend %button;
    border: solid 1px #1C4D82;
    color: #1C4D82;
    background-color: #ffffff;
  }

  .white:hover {
    background-color: #f6f6f6;
  }

  .white:active:hover {
    background-color: #eee;
    border: solid 1px #777;
    color: #777;
  }

  .white:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
    border: 0px;
  }

  .clear {
    @extend %button;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    border: 0;
    color: #1C4D82;
    background: none;
  }

  .clear:hover {
    color: #0063a8;
  }

  .clear:active:hover {
    color: #777;
  }

  .clear:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }
 
  .red {
    @extend %button;
    background-color: #c12d2a;
    border: 0;
    color: #ffffff;
  }

  .red:hover {
    background-color: #e13232;
  }

  .red:active:hover {
    background-color: #d33232;
    color: #dddddd;
  }

  .red:disabled {
    background: #cccccc;
    color: #777;
    cursor: not-allowed;
  }

  // button[data-loading=true] {
  //   background: #888;
  //   cursor: not-allowed;

  //   &:hover {
  //     background: #888;
  //   }
  // }
}

a {
  text-decoration: none;
}

.loadingContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: not-allowed;
}