.header {
  min-height: 50px;
  // color: white;
  background-color: white;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  // overflow: hidden;
  z-index: 10;
}

.headerMargins {
  margin-left: 25px;
  margin-right: 25px;
}

.outerContainer {
  height: 100%;
  width: 100%;
  background-color: #f6f6f6;
  display: inline-flex;
}

// .largeHeader {
//   margin-left: auto;
//   margin-right: auto;
//   padding-left: 60px;
//   padding-right: 60px;
// }

// .smallHeader {
//   float: left;
//   padding-left: 10px;
//   padding-right: 10px;
// }

.searchHeader {
  margin-left: auto;
  margin-right: auto;
  padding-left: 60px;
  padding-right: 60px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.viewControl {
  height: 45px;
}

.page {
  height: calc(100vh - 65px);
  background-color: #f6f6f6;
}

.header {
  width: 100%;
  // background: #ffffff;
}

.header p {
  padding-bottom: 10px;
}

.centerSelector {
  display: flex;
  justify-content: center;
  flex-basis: calc(33.33%); // 324px; //calc(100% - 760px);
  flex-grow: 0;
  // background-color: crimson;
}

.leftSelector {
  display: flex;
  justify-content: left;
  flex-basis: calc(33.33%);
  flex-grow: 0;
  gap: 10px;
  // background-color: palegreen;
}

.rightSelector {
  display: flex;
  justify-content: right;
  flex-basis: calc(33.33%); // 380px
  flex-grow: 0;
  gap: 10px;
  // background-color: mediumpurple;
}

// .outerScroll {
//   height: 100%;
//   width: 100%;
//   overflow: auto;
//   background-color: #f6f6f6;
//   display: inline-flex;
// }

.listContainer {
  height: calc(100vh - 65px);
  background-color: #f6f6f6;
  flex-grow: 20;
  z-index: 1;
}

.sideBar {
  z-index: 2;
  // height: calc(100vh - 65px);
  // background-color: white;
  // border-right-width: 1px;
  // border-right-style: solid;
  // border-right-color: rgb(221, 221, 221);

  // -webkit-transition: width 0.4s ease-in-out;
  // -moz-transition: width 0.4s ease-in-out;
  // -o-transition: width 0.4s ease-in-out;
  // transition: width 0.4s ease-in-out;

  .sideHeader {
    display: inline-flex;
    width: 100%;
    flex-flow: nowrap;
    flex-basis: content;
    justify-content: space-between;
  }
}

.sideBar {
  width: 100%;
  height: 100%;

  .sideTitle {
    // display: inline-block;
    width: 80px;
    height: 20px;
    font-size: 20px;
    margin-top: 15px;
    margin-left: 20px;
    color: #222222;
    flex-grow: 1;
  }

  .sideDescription {
    width: 100%;
    font-size: 16px; 
    color: #3c3c3c;
  }

  .closeFilterButton {
    // display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    width: 20px;
    height: 20px;
    z-index: 3;
    cursor: pointer;
  }

  .sideContent {
    overflow: auto;
    max-height: 100%;
    // height: calc(100% - 50px);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.sideBar[data-fullscreen=true] {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100% - 50px);
  width: 100%;
  min-width: 100%;
  overflow-y: auto;
  
  .sideTitle {
    // display: inline-block;
    width: 80px;
    height: 20px;
    font-size: 20px;
    margin-top: 15px;
    margin-left: 20px;
    color: #222222;
    flex-grow: 1;
  }

  .sideDescription {
    width: 100%;
    font-size: 16px; 
    color: #3c3c3c;
  }

  .closeFilterButton {
    // display: inline-block;
    margin-top: 10px;
    margin-right: 10px;
    padding-top: 10px;
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    width: 20px;
    height: 20px;
    z-index: 3;
    cursor: pointer;
  }

  .sideContent {
    overflow: unset;
    max-height: 100%;
    max-width: 300px;
    // height: calc(100% - 50px);
    padding-left: 20px;
    padding-right: 20px;
  }
}

.topRow {
  padding-top: 25px;
}

.noPaddingBlock {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  margin-bottom: 20px;
}

.block {
  position: relative;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  padding: 10px 20px;
  flex-grow: 1;
  margin-left: "10px";
  margin-right: "10px";
  font-size: 16px;
  font-weight: 400;
  min-height: 430px;
}

.blockTitle {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
  margin-left: 5px;
  margin-bottom: 5px;
}

.blockInnerTitle {
  font-size: 20px;
  font-weight: 600;
  color: #222222;
  margin-top: 8px;
  margin-bottom: 8px;
}

.blockInnerDescription {
  font-size: 16px;
  font-weight: 400;
  color: #222222;
  margin-bottom: 5px;
}

.blockInnerStatusMessage {
  display: flex;
  // align-items: center;
  // justify-content: center;
  // min-height: 150px;
  font-size: 16px;
  font-weight: 600;
  color: #222; 
  margin-top: 20px;
  margin-bottom: 20px;
}

.blockFootnote {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-top: 5px;
  text-align: center;
}

.widthContainer {
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  max-width: 1400px;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.icon {
  float: left;
  padding-right: 20px;
}

.title {
  font-family: Source Sans Pro;
  font-size: 35px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
}

.subtitle {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0c0f26;
}

.rowContainer {
  border: solid 1px #dbdbdb;
  border-radius: 9px;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .arrowIcon {
    margin-top: 50px;
  }
}

.arrowBox {
  padding-left: 10px;
  cursor: pointer;
  margin-right: -5px; // do show highlighting to the right edge
}

.arrowBox:hover {
  background-color: #dbdbdb;
}

.row {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.col {
  position: relative;
  height: 100%;
  width: 100%;
}

.col30 {
  position: relative;
  height: 100%;
  width: 30%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.col50 {
  position: relative;
  height: 100%;
  width: 50%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.col70 {
  position: relative;
  height: 100%;
  width: 70%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
}

.scroll {
  position: relative;
  overflow: auto;
  max-height: 100%;
  padding-left: 60px;
  padding-right: 60px;
  height: 100%;
}

.wideScroll {
  overflow: auto;
  max-height: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

.box {
  display: flex;
  background-color: rgb(255, 255, 255);
  min-height: 50px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  box-sizing: border-box;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 1px;
  margin-bottom: 10px;
  padding: 10px;
}

.tableLineHeight {
  padding-top: 7px;
  line-height: 1.5em;
}

.selectionHeader {
  h2 {
    font-size: 42px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  h3 {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  margin-top: 40px;
  text-align: center;
}

// Footer
.footer {
  z-index: 1;
  position: fixed;
  left: 0px;
  bottom: 0px;
  min-height: 84px; // + 36px
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 0 0 #DBDBDB;

  .footerContent {
    margin-left: 15px;
    margin-right: 15px;

    .selections {
      display: inline-flex;
      flex-wrap: wrap;
    }

    .frame {
      margin-right: 10px;
    }

    .buttons {
      display: flex;
      float: right;
    
      .simple { 
        box-sizing: border-box;
        min-height: 1px;
        position: relative;
        padding-right: 15px;
        width: 255px;
        max-width: 255px;
        // flex: 1 0 0px;
        margin-left: 0%;
        right: auto;
        left: auto;
      }
    }
  }
}

.editPencil {
  cursor: pointer;
  margin-bottom: 20px;
  padding-left: 10px;
  display: inline-flex;
}

.container {
  margin-bottom: 50px;
}

.logoImg {
  width: 64px;
  height: 64px;
  background-color: white;
}

.backButton {
  margin-top: 10px;
  height: 26px;
  line-height: 25px;
  cursor: pointer;
}

.weekDayCell {
  text-align: center;
  background-color: var(--tooltip-color);
}

.infoContainer {
  display: block;
  position: absolute;
  top: 1px;
  right: -12px;
  width: 25px;
  height: 25px;
  z-index: 30;
}

// Report

h2 {
  margin-block-start: 1.5em;
  // margin-block-end: 2em;
}

ul, ol {
  margin-block-start: 1.5em;
  margin-block-end: 2em;
}

li {
  font-size: 16px;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(216, 216, 216, 0.7);
  z-index: 2;
  cursor: pointer;
}

.closeButton {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  cursor: pointer;
  z-index: 3;

  font-size: 20px;
  font-weight: 100;
  color: #333;

  display: flex;
  align-items: center;
  justify-content: center;
}