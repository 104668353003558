.footer {
  z-index: 100;
  position: fixed;
  left: 0px;
  bottom: 0px;
  min-height: 84px;
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 0 0 #DBDBDB;
}

.buttons {
  display: flex;
  float: right;
  margin-left: 15px;
  margin-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;

  .simple { 
    box-sizing: border-box;
    min-height: 1px;
    position: relative;
    padding-right: 15px;
    width: 255px;
    max-width: 255px;
    // flex: 1 0 0px;
    margin-left: 0%;
    right: auto;
    left: auto;
  }
}

.footerContent {
  position: relative;
  height: 100%;
  width: inherit;
  justify-content: space-between;
  display: inline-flex;
  flex-grow: 0;
  flex-shrink: 0;
  vertical-align: top;

  .selections {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
  }
}