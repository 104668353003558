.buttonContainer {
  position: relative;

  %borderButton {
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-size: 18px;
    width: 100%;
    margin-top: 15px;
    border-radius: 7px;
    background-color: #ffffff;
    padding: 16px 39px 15px 30px;
    display: block;
    position: relative;
    text-decoration: none;
    text-align: center;
  }

  .blue {
    @extend %borderButton;
    border: solid 1px #1C4D82;
    color: #1C4D82;
  }

  .green {
    @extend %borderButton;
    border: solid 1px #5CA94E;
    color: #5CA94E;
  }

  .red {
    @extend %borderButton;
    border: solid 1px rgb(194, 4, 4);
    color: rgb(194, 4, 4);
  }

  .purple {
    @extend %borderButton;
    border: solid 1px rgb(108, 78, 169);
    color: rgb(108, 78, 169);
  }

  .orange {
    @extend %borderButton;
    border: solid 1px rgb(225, 134, 37);
    color: rgb(225, 134, 37);
  }

  .teal {
    @extend %borderButton;
    border: solid 1px rgb(7, 126, 128);
    color: rgb(7, 126, 128);
  }

  .closeContainer {
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    width: 22px;
    height: 22px;

    .closeIcon {
      width: 16px;
      height: 16px;
      margin-top: 4px;
      margin-left: 3px;
      background-color: gray;
      -webkit-mask-image: url('./close.svg');
      mask-image: url('./close.svg');
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .closeContainer:hover .closeIcon {
    background-color: #222;
  }

  .typeInfo {
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 12px;
    font-weight: 100;
    font-family: sans-serif;
    color: #6f6f6f;
  }
}

a {
  text-decoration: none;
}

.frame {
  margin-left: 15px;
  display: inline-block;
}