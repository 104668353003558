.freetext {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  // display: inline-block;
}

.inlineFreetext {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  display: inline-block;
}

.freetext h2, .inlineFreetext h2  {
  font-size: 26px;
  line-height: 38.5px;
  min-height: 38.5px;
  margin: 0;
  font-family: Source Sans Pro;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  color: #0c0f26
}

.freetext p, .inlineFreetext p {
  font-family: Source Sans Pro;
  font-size: 15px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  text-align: left;
  color: #0c0f26;
}