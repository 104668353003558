.wrapper {
  position: fixed;
  z-index: 10000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .overlay {
    background-color: rgba(216, 216, 216, 0.7);
    width: 100%;
    height: 100%;
  }

  .modal {
    position: absolute;
    top: calc(50%);
    background: white;
    min-width: 30%;
    padding: 50px;
    left: calc(50%);
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    border: solid 1px #ccc;
    border-radius: 12px;

    @media screen and (max-width: 767px) {
      padding: 10px;
      width: 90%;
    }

    .title {
      font-size: 30px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
      margin-bottom: 0;
    }

    .text {
      font-size: 18px;
      font-weight: 400;
      line-height: 1.5;
      text-align: center;
    }
  }

}
