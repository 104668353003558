.labelContainer {
  position: relative;
  display: inline-block;
  top: -5px;
  margin-left: 10px; 
  line-height: normal;
}

.rowLabelContainer {
  line-height: normal;
  margin-top: 10px;
}

.fullRowLabelContainer {
  position: relative;
  display: inline-block;
  line-height: normal;
}

span {
  font-size: 14px;
}

%label {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px 10px 5px 10px;
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
}

.blue {
  @extend %label;
  border: 0;
  background-color: #1C4D82;
  color: #ffffff;
}

.green {
  @extend %label;
  border: 0;
  background-color: #1c9548;
  color: #ffffff;
}

.grey {
  @extend %label;
  border: 0;
  background-color: #c3c3c3;
  color: #ffffff;
}

.red {
  @extend %label;
  background-color: #c12d2a;
  border: 0;
  color: #ffffff;
}