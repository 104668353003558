.tabs {
  display: flex;
  // width: calc(100% - 120px);
  width: 100%;
  min-height: 60px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  padding-left: 60px;
  padding-right: 60px;

  // 992px (md) media (mobile threshold)
  @media (max-width: 992px) {
    padding-left: 10px;
    padding-right: 10px;
  }
}

.tabsNoMargin {
  display: flex;
  width: 100%;
  min-height: 50px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  padding-left: 0px;
  padding-right: 0px;
}

.tabsSmallMargin {
  display: flex;
  width: 100%;
  min-height: 50px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  padding-left: 10px;
  padding-right: 10px;
}

.burger {
  height: 80px;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  margin-left: auto;
  margin-right: auto;
  padding-left: 10px;
  padding-right: 10px;
}

.menuItem {
  float: left;
  padding-top: 20px;
  // padding-bottom: 20px;
  display: block;
}

.menuItem a {
  padding-top: 20px;
  margin-right: 25px;
  color: #1C4D82;
  cursor: pointer;
  text-decoration: none;
  font-weight: 400;
}

.tabs .menuItem a {
  padding-bottom: 21px;
}

.tabsSlim .menuItem a {
  padding-bottom: 11px;
}

.menuItem a:hover {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
  text-decoration: none;
}

.tabs .selected, .tabsSlim .selected {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
}

.menuItemRight {
  padding-top: 20px;
  padding-bottom: 20px;
  display: block;
  float: right;
}

// .menuItemRight, .menuItemRight a {
//   padding-top: 20px;
//   padding-bottom: 20px;
//   margin-right: 25px;
//   color: #1C4D82;
//   cursor: pointer;
//   text-decoration: none;
// }

.menuItemRight {
  display: block;
  padding: 20px 16px;
  margin-top: 18px;
  text-decoration: none;
  color: #1C4D82;
  cursor: pointer;
  position: relative;
}

.menuItemRight:hover, .menuItemRight a:hover {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
  text-decoration: none;
}

.dropDownMenu ul {
  list-style-type: none;
  float: left;
  line-height: 40px;
  width: 100%;
}

.dropDownMenuItem a {
  padding-top: 10px;
  padding-bottom: 5px;
  margin-right: 25px;
  color: #1C4D82;
  cursor: pointer;
  text-decoration: none;
}

.dropDownMenuItem a:hover {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
}

.dropDownMenuItemSelected {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
}

.notification {
  color: #666;
  padding-left: 6px;
  padding-right: 0px;
}

.subHeader {
  min-height: 50px;
  color: white;
  background-color: white;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  z-index: 10;
}

.subHeaderMargins {
  margin-left: 25px;
  margin-right: 25px;
}