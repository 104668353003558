.loginForm {
  //padding-top: 50px;
  //text-align: center;
}

.outerScroll {
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
}

.topRow {
  padding-top: 30px;
}

.screenshot {
  padding-top: 40px;
}

.screenshot img {
  float: right;
  border-radius: 7px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 20px;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 50px;
}

.mobileContainer {
  padding-left: 20px;
  padding-right: 20px;
  margin-bottom: 50px;
}

.listContainer {
  height: 100vh; //calc(100vh - 65px);
  background-color: #f6f6f6;
  flex-grow: 20;
  z-index: 1;
}

.scroll {
  overflow: auto;
  max-height: 100%;
  height: 100%;
}

.right {
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  width: 100%;
  max-width: 410px;
  padding-bottom: 50px;
}

.block {
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(226, 226, 226);
  padding: 15px 20px;
  margin: 10px;
  
  p {
    font-size: 16px;
  }

  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
}

.aboveBlockTitle {
  font-size: 20px;
  font-weight: 600;
  color: rgb(28,77,130);
  font-size: 1.875rem;
  line-height: 2.25rem;
  margin-bottom: 5px;
  margin-left: 20px;
}

.blockTitle {
  font-size: 20px;
  font-weight: 400;
  color: #222222;
  margin-left: 5px;
  margin-bottom: 5px;
}

.blockFootnote {
  font-size: 14px;
  font-weight: 400;
  color: #777;
  margin-top: 5px;
  text-align: center;
}

.buttonPadding {
  padding-top: 10px;
  // padding-bottom: 10px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
}

.leftColumn {
  height: 100%;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
  background-color: white;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.rightColumn {
  height: 100%;
  background-color: #f6f6f6;
  display: flex;
  align-items: center;
}

.imagePadding {
  // padding-left: 100px;
  // padding-right: 100px;
}

.logoContainer {
  display: flex;
  width: 100%;
  margin-top: auto;
  align-items: center;
  justify-content: center;

  .logoText {
    color: #1C4D82;
    font-size: 70px;
    line-height: 70px;
    font-weight: 700;
    margin-top: 5px;
    margin-left: 5px;
    margin-right: 20px;
  }

  img {
    height: 120px;
    width: 120px;
  }
}

.animationContainer {
  width: 100%;
  max-width: 500px;
  margin: auto;
  margin-top: 0;
  padding-bottom: 50px;
}

.footnote { 
  position: absolute;
  right: 10px;
  bottom: 10px;
  color:#555;
}