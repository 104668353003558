.inputGroup {
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;
  text-align: left;

  label {
    font-size: 18px;
    color: #222222;
  }

  input {
    outline: 0;
    border-width: 0 0 2px;
    border-color: #dbdbdb;
    border-style: solid;
    margin-top: 5px;
    width: 100%;
    font-size: 24px;
    font-weight: 400;
    line-height: 1.42;
    color: #6b7073;
  }

  [required]::-webkit-input-placeholder {
    color: #6b7073;
  }

  textarea {
    outline: 0;
    border-width: 1px 1px 2px;
    border-color: #dbdbdb;
    margin-top: 10px;
    width: 100%;
    resize: vertical;
    min-height: 50px;
  }
}

.inputWrapper {
  position: relative;
}

.errorMessage {
  font-size: 17px;
  font-weight: 400;
  font-style: oblique;
  line-height: 1.41;
  color: #6e777d;
}
