.outerScroll {
  height: 100%;
  overflow: auto;
  background-color: #f6f6f6;
}

.innerContainer {
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

.container {
  padding-left: 60px;
  padding-right: 60px;
  margin-bottom: 50px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  flex-grow: 1;
  max-width: 1000px;
  width: 100%;
  box-sizing: border-box;
  flex-wrap: wrap;
}

.container li {
  list-style-type: disc;
}

.container ol {
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 40px;
}

.container ol li {
  list-style-type: inherit;
}

.footnote {
  text-align: end;
  font-size: 15px;
  color: #444;
  margin-top: 50px;
}

h2, h3, h4 {
  font-weight: 600 !important;
  margin-block-start: 1.5em;
  margin-block-end: 0.3em;
}

p {
  margin-block-start: 0.3em;
}