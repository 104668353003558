.app {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  display: flex;
  flex-direction: column;
  flex-flow: column;
  height: 100vh;
  overflow: hidden;
}

input:focus {
  outline-offset: 0;
}