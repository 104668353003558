.searchContainer {
  display: block;
}

.dropDownContainer {
  margin-right: 20px;
  min-width: 360px;
}

.dropDownRow {
  margin-top: 1px;
}

.box {
  display: block;
  flex-grow: 1;
  // background-color: rgb(255, 255, 255);
  
  overflow: hidden;
}

.addQueryBox {
  display: block;
  flex-grow: 1;
  border-radius: 6px;
  border-width: 1px;
  border-style: dashed;
  border-color: #bbb;
  box-sizing: border-box;
  margin-top: 10px;
  margin-bottom: 20px;
}

.queryContainer {
  display: flex;
  background-color: rgb(255, 255, 255);
  min-height: 50px;
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  box-sizing: border-box;
  margin-left: 1px;
  margin-right: 1px;
  margin-top: 1px;
  margin-bottom: 10px;
}

.queryContainer[data-selected=true] {
  -moz-box-shadow: 0 0 0 1px #1c4d82;
  -webkit-box-shadow: 0 0 0 1px #1c4d82;
  box-shadow: 0 0 0 1px #1c4d82;
}

.innerBox {
  display: flex;
  flex-wrap: wrap;
  // align-content: space-around;
  margin-top: 5px;
  margin-bottom: 6px;
  padding-left: 10px;
  width: 100%;
}

.searchField {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  // background-color: rgb(255, 255, 255) !important;
  margin-top: 7px;
  margin-bottom: 5px;
  font-size: 17px;
  border: 0;
  padding-left: 5px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 100px;
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
}
input[data-autocompleted] {
    background-color: transparent !important;
}

.tag {
  margin-top: 6px;
}

*:focus {
  outline: none;
}

.queryOptions {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.queryAddButton {
  height: 100%;
  width: 100%;
  cursor: pointer;
}

.queryOptionButton {
  height: 100%;
  width: 50px;
  cursor: pointer;
  flex-basis: content;
}

.queryIcon {
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 50px;
  font-size: 18px;
}

.searchButton {
  display: flex;
  height: 50px;
  width: 120px;
  min-width: 100px;
  margin-left: 20px;
  cursor: pointer;
  background-color: #1C4D82;
  border-radius: 6px;
  align-self: center;

  .searchButtonText {
    display: inline-block;
    width: 100%;
    color: white;
    text-align: center;
    line-height: 50px;
    font-size: 17px;
    white-space: nowrap;
  }
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 90%;
}

.separator {
  border-width: 2px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 1px;
  border-style: dashed;
  border-color: #ccc;
}

.separatorText {
  display: block;
  position: absolute;
  color: #999;
  background-color: white;
  width: 40px;
  text-align: center;
  user-select: none;
}

.separatorMiddel {
  display: block;
  position: relative;
  left: calc(50% - 20px);
  top: -10px;
}