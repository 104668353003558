.inputGroup {
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
  text-align: left;

  label {
    display: inline-block;
    font-family: "Source Sans Pro";
    font-size: 14px;
    font-weight: 600;
    color: #000;
    min-height: 18px;
    margin-bottom: 5px;
  }

  input {
    border-width: 0;
    outline-color: #D8D8D8;
    outline-width: 1px;
    outline-style: solid;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: white;
    width: 100%;
    font-size: 17px;
    font-weight: 400;
    line-height: 1.6;
    padding: 6px 12px;
    color: #444;
    cursor: "auto";

    // Testing a more standard height
    // This is 42px when including the border
    height: 40px;
  }

  input:disabled {
    background-color: rgb(249, 249, 249);
    color: rgb(102, 102, 102);
  }

  [required]::-webkit-input-placeholder {
    color: #6b7073;
  }

  textarea {
    outline: 0;
    border-width: 1px 1px 2px;
    border-color: #dbdbdb;
    margin-top: 10px;
    width: 100%;
    resize: vertical;
    min-height: 50px;
  }
}

.inputWrapper {
  position: relative;
  flex: 1;
  // margin-top: 5px;
}

.info {
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
}

.rowWrapper {
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 10px;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}