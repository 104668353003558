.slimContainer {
  position: relative;
  display: inline-block;

  %button {
    cursor: pointer;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-size: 18px;
    height: 42px;
    border-radius: 7px;
    padding: 8px;
    display: block;
    position: relative;
    text-decoration: none;
    text-align: center;
    user-select: none;
  }

  .blue {
    @extend %button;
    border: 0;
    background-color: #1C4D82;
    color: #ffffff;
  }

  .blue:hover {
    background-color: #215997;
  }

  .blue:active:hover {
    background-color: #2566ac;
    color: #dddddd;
  }

  button:disabled,
  button[disabled],
  button[disabled]:hover {
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }

  .white {
    @extend %button;
    padding: 7px 8px;
    border: solid 1px #1C4D82;
    color: #1C4D82;
    background-color: #ffffff;
  }

  .white:hover {
    background-color: #f6f6f6;
  }

  .white:active:hover {
    background-color: #eee;
    border: solid 1px #666666;
    color: #666666;
  }

  .white:disabled {
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border: 0px;
  }

  .whiteRed {
    @extend %button;
    padding: 7px 8px;
    border: solid 1px #c12d2a;
    color: #c12d2a;
    background-color: #ffffff;
  }

  .whiteRed:hover {
    background-color: #f6f6f6;
  }

  .whiteRed:active:hover {
    background-color: #eee;
    border: solid 1px #666666;
    color: #666666;
  }

  .whiteRed:disabled {
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border: 0px;
  }

  .clear {
    @extend %button;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    border: 0;
    color: #1C4D82;
    background: none;
  }

  .clear:hover {
    color: #0063a8;
  }

  .clear:active:hover {
    color: #666666;
  }

  .clearGray {
    @extend %button;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    border: 0;
    color: #555;
    background: none;
  }

  .clearGray:hover {
    color: #333;
  }

  .clearGray:active:hover {
    color: #333;
  }
 
 .red {
    @extend %button;
    background-color: #c12d2a;
    border: 0;
    color: #ffffff;
  }

  .red:hover {
    background-color: #e13232;
  }

  .red:active:hover {
    background-color: #d33232;
    color: #dddddd;
  }

  .gray {
    @extend %button;
    border: 0;
    background-color: #444;
    color: #ffffff;
  }

  .gray:hover {
    background-color: #333;
  }

  .gray:active:hover {
    background-color: #222;
    color: #dddddd;
  }

  .whiteGray {
    @extend %button;
    padding: 7px 8px;
    border: solid 1px #666;
    color: #666;
    background-color: #ffffff;
  }

  .whiteGray:hover {
    background-color: #f9f9f9;
  }

  .whiteGray:active:hover {
    background-color: #eee;
    border: solid 1px #333;
    color: #333;
  }

  .whiteGray:disabled {
    background: #cccccc;
    color: #888;
    cursor: not-allowed;
    border: 0px;
  }
}

a {
  text-decoration: none;
}