.textbox {
  position: relative;
  margin-top: 20px;
}

.topTextbox {
  position: relative;
}

.label {
  float: left;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.textfield {
  width: 100%;
  height: 250px;
  border-radius: 5px;
  border: solid 1px #D8D8D8;
  padding: 12px 12px;
  box-sizing: border-box;
  resize: none;
  color: #0c0f26;
  font-size: 15px;
  font-family: Helvetica, Arial, sans-serif;
  margin-top: 5px;
}

.buttons {
  position: absolute;
  right: 7px;
  bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}

.buttonContainer {
  margin-left: 5px;
}

%button {
  cursor: pointer;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 18px;
  width: 100%;
  border-radius: 7px;
  padding: 6px 12px 7px 12px;
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
}

.button {
  @extend %button;
  border: solid 1px #979797;
  background-color: #ffffff;
  color: #333;
}

.button:hover {
  background-color: #f6f6f6;
}

.button:active:hover {
  background-color: #eee;
  border: solid 1px #666666;
  color: #666666;
}

.info {
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
}