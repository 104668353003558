.select {
  border-radius: 7px;
  max-width: 100%;
  max-height: 50px;
  display: flex;
  align-items: center;
  border: solid 1px #1C4D82;
  max-width: 200px;
  overflow: hidden;
  background: #ffffff url("./caret2.png") no-repeat 95% 50%;
  background-size: 10%;

  @media (max-width: 360px) {
    width: 130px;
  }
}

.select select {
  font-family: Source Sans Pro;
  font-size: 14px;
  padding: 7px 40px 7px 16px;
  border: none;
  box-shadow: none;
  background: transparent;
  background-image: none;
  -webkit-appearance: none;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;

  @media (max-width: 360px) {
    max-width: 120px;
  }
}

.partnerSelector select:focus {
  outline: none;
}

// For when "none" is selected
.none {
  select {
    color: #555;
  }
}