body {
  margin: 0;
  padding: 0;
  font-family: "Source Sans Pro";
  -webkit-font-smoothing: antialiased;
}

// Standard colors
$darkBlue: #1C4D82;
$lightBlue: #6AE6E5;
$darkGreen: #009584;
$lightGreen: #3EB68A;

// Light
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-300.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 300;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-300italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-300italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-LightItalic.ttf) format('truetype');
}

// Regular
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-regular.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-Italic.ttf) format('truetype');
}

// SemiBold
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 600;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-600.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 600;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-600italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-600italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-SemiBoldItalic.ttf) format('truetype');
}

// Bold
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-700.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: italic;
  font-weight: 700;
  font-display: swap;
  -webkit-font-smoothing: antialiased;
  src: url('./fonts/source-sans-pro-v21-latin-700italic.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('./fonts/source-sans-pro-v21-latin-700italic.woff') format('woff'), /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
       url(./fonts/SourceSansPro-BoldItalic.ttf) format('truetype');
}

::-webkit-input-placeholder {
  color: #999;
}

::-moz-placeholder {
  color: #999;
}

::-ms-placeholder {
  color: #999;
}

::placeholder {
  color: #999;
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
  line-height: normal;
  -webkit-font-smoothing: antialiased;
  font-weight: 400;
}

p, span, div {
  font-size: 15px;
}

button span {
  font-size: 16px;
  font-weight: 600;
}

// .rt-resizable-header-content {
//   font-family: "Source Sans Pro", Helvetica, Arial, sans-serif;
//   color: #111;
//   font-weight: 600;
// }

a {
  color: $darkBlue;
  text-decoration: none;
}