.buttonContainer {
  position: relative;

  .button {
    cursor: pointer;
    font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
    font-size: 30px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 20px;
    border-radius: 7px;
    padding: 40px;
    display: block;
    position: relative;
    text-decoration: none;
    text-align: center;
    border: solid 1px #1C4D82;
    color: #1C4D82;
    background-color: #ffffff;
  }

  button[data-singleline=true] {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  button:disabled,
  button[disabled]{
    background: #cccccc;
    color: #666666;
    cursor: not-allowed;
    border: 0px;
  }

  button:hover {
    background-color: #f6f6f6;
  }

  button:active:hover {
    background-color: #eee;
    border: solid 1px #666666;
    color: #666666;
  }
}

a {
  text-decoration: none;
}