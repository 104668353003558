.topRow {
  padding-top: 30px;
}

.video {
  background-color: aquamarine;
  //visibility: hidden;
  height: 1px;
}

.image {
  background-color: rebeccapurple;
  max-width: 100%;
  width: 100%;
  max-height: 100%;
  height: 100%;
}

.container {
  margin-bottom: 50px;
}