.searchContainer {
  display: flex;
  width: 100%;
  max-width: 800px;
}

.dropDownContainer {
  margin-right: 20px;
  min-width: 360px;
}

.dropDownRow {
  margin-top: 1px;
}

.box {
  display: block;
  flex-grow: 1;
  background-color: rgb(255, 255, 255);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221, 221, 221);
  box-sizing: border-box;
  overflow: hidden;
}

.queryContainer {
  display: flex;
  min-height: 40px;
  border-width: 1px;
}

.queryContainer[data-selected=true] {
  border-color: #dddddd;
}

.queryContainer[data-selected=false] {
  background-color: #eee;
  border-color: rgb(221, 221, 221);
}

.queryContainer[data-first=true][data-last=false] {
  border-bottom-style: dashed;
}

.queryContainer[data-first=false][data-last=false] {
  border-bottom-style: dashed;
}

.innerBox {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  align-content: space-around;
  padding-left: 10px;
  width: 100%;
  min-width: 200px;
}

.searchField {
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 17px;
  border: 0;
  padding-left: 5px;
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 100px;
  background: transparent;

}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input[data-autocompleted] {
  background-color: transparent !important;
}

.tag {
  // margin-top: 6px;
}

*:focus {
  outline: none;
}

.queryOptions {
  display: flex;
  height: 100%;
}

.queryAddButton {
  height: 100%;
  width: 50px;
  cursor: pointer;
}

.queryRemoveButton {
  height: 100%;
  width: 50px;
  cursor: pointer;
}

.queryIcon {
  display: inline-block;
  width: 100%;
  text-align: center;
  line-height: 30px;
}

.searchButton {
  display: flex;
  height: 42px;
  width: 120px;
  min-width: 100px;
  margin-left: 20px;
  cursor: pointer;
  background-color: #1C4D82;
  border-radius: 6px;
  align-self: center;

  .searchButtonText {
    display: inline-block;
    width: 100%;
    color: white;
    text-align: center;
    line-height: 40px;
    font-size: 17px;
    white-space: nowrap;
  }
}

input {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  width: 90%;
}

.separator {
  border-width: 2px;
  border-right: 0px;
  border-left: 0px;
  border-top: 0px;
  border-bottom: 1px;
  border-style: dashed;
  border-color: #ccc;
}

.separatorText {
  display: block;
  position: absolute;
  color: #999;
  background-color: white;
  width: 40px;
  text-align: center;
  user-select: none;
}

.separatorMiddel {
  display: block;
  position: relative;
  left: calc(50% - 20px);
  top: -10px;
}

.clearButton {
  height: 100%;
  width: 39px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}