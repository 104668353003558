.treeNodes {
  margin: 0;
  padding: 0;
}

.treeNodesList {
  list-style-type: none;
}

.expandIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.locationLabel {
  font-size: 18px;
  cursor: pointer;
}

.treeNodeCheckbox {
  font-size: 20px;
  cursor: pointer;
}
