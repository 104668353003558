.crumbContainer {
  display: inline-flex;

  .link {
    display: inline-block;
    padding-right: 10px;
    cursor: pointer;
    color: #1C4D82;
    
    .linkText {
      // font-family: Source Sans Pro;
      font-size: 16px;
    }
  }
}

.crumbWrapper{
  display: inline-flex;
  margin-left: 25px;
}
