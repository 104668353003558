/**
  *
            New styles
  *
*/
.filterInputContainer {
  padding: 10px;
  background-color: #1C4D82;
  display: flex;
  flex-direction: column;
  width: 150px;
  border-radius: 7px;
  color: white;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 5px;

  span {
    font-size: 10px;
  }

  input {
    border: 0;
    background: 0;
    filter: invert(1);
    font-weight: 600;
  }
}

.floatingBottomFooter {
  z-index: 100;
  position: fixed;
  left: 0px;
  bottom: 0px;
  min-height: 84px;
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 0 0 #DBDBDB;

  .buttonWrapper {
    display: flex;
    float: right;
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 10px;
    padding-bottom: 10px; 
    gap: 20px;
  }
}

.twoColumnHeightCell {
  display: flex;
  flex-direction: column;
  max-height: 60px;
  height: 100px;
  line-height: initial;
  justify-content: center;
  align-items: center;

  .smallText {
    font-size: 12px;
  }
}

.row {
  display: flex;
  position: relative;
  height: 100%;
  width: 100%;
}

.filterCol {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 30%;
  background-color: #F6F6F6;
  box-shadow: inset -1px 0 0 0 #dbdbdb;
  overflow: auto;
  max-height: 100%;
}

.filterContainer {
  margin-right: 20px;
  margin-left: 60px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.listCol {
  display: inline-block;
  position: relative;
  height: 100%;
  width: 70%;
  overflow: auto;
  max-height: 100%;
}

.listContainer {
  margin-right: 60px;
  margin-left: 20px;
  margin-top: 20px;
  margin-bottom: 40px;
}

.mainContainer {
  height: calc(100vh - 272px);
}


