.header {
  min-height: 65px;
  // color: white;
  background-color: white;
  box-shadow: inset 0 -1px 0 0 #DBDBDB;
  // overflow: hidden;
  z-index: 100;
}

.largeMargin {
  padding-left: 60px;
  padding-right: 60px;
}

.smallMargin {
  padding-left: 10px;
  padding-right: 10px;
}

.userName {
  float: right;
  max-width: 240px;
  margin-left: 10px;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  font-size: 18px;
  white-space: nowrap;

  @media(max-width: 420px) {
    display: none
  }

  .user {
    margin-top: 6px;
    margin-right: 8px;
    margin-left: 10px;
    height: 17px;
    width: 17px;
    background-image: url('./user.svg');
    float: left;
    background-repeat: no-repeat;
    background-position: center;
  }
}

.dropDown {
  padding-top: 70px;
}

.dropDown ul {
  list-style-type: none;
  float: left;
  line-height: 31px;
}

.dropDown li a {
  padding-bottom: 5px;
  display: block;
  text-decoration: none;
  color: #1C4D82;
  cursor: pointer;
}

.navigationTabs ul {
  margin: 0;
  list-style-type: none;
  float: left;
  line-height: 25px;
  padding-left: 0px;
}

.navigationBurger ul {
  margin: 0;
  list-style-type: none;
  float: right;
  line-height: 25px;
  padding-left: 0px;
}

.rightTab ul {
  margin: 0;
  list-style-type: none;
  float: right;
  line-height: 25px;
  padding-left: 0px;
}

.logoutTab ul {
  margin: 0;
  list-style-type: none;
  float: right;
  line-height: 25px;
  padding-left: 0px;
}

.navigationTabs li,
.logoutTab li,
.rightTab li {
  float: left;
}

.navigationBurger li {
  float: right;
}

.navigationTabs li a,
.navigation,
.rightTab li a,
.logOut,
.logIn {
  display: block;
  font-size: 16px;
  padding: 20px 16px;
  text-decoration: none;
  color: #1C4D82;
  cursor: pointer;
  position: relative;

  &:after {
    content: '';
    width: 80%;
    display: none;
    height: 2px;
    background-color: #1C4D82;
    position: absolute;
    bottom: 0px;
    left: 10%;
  }
}

.accountIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 65px;
  width: 65px;
  cursor: pointer;
}

.accountMenu {
  position: absolute;
  top: 60px;
  right: 8px;
  background-color: white;
  box-shadow: 0 4px 6px -1px rgba(0,0,0,0.1), 0 2px 4px -1px rgba(0,0,0,0.06);
  border-radius: 6px;
  border-width: 1px;
  border-style: solid;
  border-color: rgb(221,221,221);
  padding: 10px 0;
  z-index: 100;
  width: 180px;

  // White square with 2 grey borders (rotated 180 degrees)
  &:before {
    content: "";
    position: absolute;
    top: -7px;
    right: 16px;
    width: 12px;
    height: 12px;
    background-color: white;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(221,221,221) transparent transparent rgb(221,221,221);
    transform: rotate(45deg);
    z-index: 100;
  }
}

.accountMenuItem {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  color: #1C4D82;
  text-decoration: none;
  display: block;
  text-align: left;
  &:hover {
    background-color: #F2F2F2;
  }
}

.exitBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background-color: rgba(0, 0, 0, 0.05);
  z-index: 99;
}

.dropDown {
  .logOut {
    padding: 0;
    height: 36px;
  
    &:after {
      left: 0;
      background-image: none;
      background-color: #1C4D82;
    }
  }
}

.dropDown li a:hover,
.dropDown .selected li a {
  box-shadow: inset 0 -2px 0 0 #1C4D82;
}

.navigationTabs li a:hover,
.navigation:hover,
.navigationTabs .selected,
.rightTab li a:hover,
.rightTab .selected,
.logOut:hover {
  &:after {
    display: block;
  }
}

.title {
  text-align: left;
  float: left;
  color: #0C0F26;
  font-size: 13px;
  font-weight: 600;
  line-height: 23px;
  padding-top: 15px;
  padding-bottom: 0;
  padding-left: 22px;
  padding-right: 22px;
}

.titleSignIn {
  text-align: left;
  float: left;
  color: #1C4D82;
  font-size: 30px;
  font-weight: 700;
  line-height: 46px;
  padding: 8px;
  padding-bottom: 0;
}

.subtitle {
  margin-top: 2px;
  color: #6B7073;
  font-size: 21px;
}

.brandImage {
  padding-top: 0px;
  float: left;
  cursor: pointer;
}

.logoutTab .logOut {
  margin-left: 25px;
}