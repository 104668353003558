.textbox {
  display: block;
  position: relative;
  padding-top: 20px;
}

.topTextbox {
  position: relative;
}

.label {
  display: inline-block;
  font-family: "Source Sans Pro";
  font-size: 14px;
  font-weight: 600;
  color: #000;
}

.buttonContainer {
  position: absolute;
  right: 7px;
  bottom: 12px;
}

%button {
  cursor: pointer;
  font-family: Source Sans Pro, Helvetica, Arial, sans-serif;
  font-size: 18px;
  width: 100%;
  border-radius: 7px;
  padding: 6px 12px 7px 12px;
  display: block;
  position: relative;
  text-decoration: none;
  text-align: center;
}

.button {
  @extend %button;
  border: solid 1px #979797;
  color: #0c0f26;
  background-color: #ffffff;
}

.button:hover {
  background-color: #f6f6f6;
}

.button:active:hover {
  background-color: #eee;
  border: solid 1px #666666;
  color: #666666;
}

.info {
  font-size: 14px;
  color: #6e777d;
  float: right;
  padding-right: 4px;
}