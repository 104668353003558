.header {
  width: 100%;
  background: #ffffff;
}

.header p {
  padding-bottom: 10px;
}

.outerScroll {
  height: 100%;
  overflow: auto;
  background-color: #F6F6F6;
}

.topRow {
  padding-top: 50px;
}

.headerRow {
  display: flex;
  justify-content: space-between;
  gap: 40px;
  padding-top: 25px;
  padding-bottom: 20px;
  align-items: center;

  .searchCol {
    width: 400px;
    max-width: 100%;
  }

  .buttonCol {
    display: flex;
    gap: 20px;
    max-width: 100%;
  }
}

.icon {
  float: left;
  padding-right: 20px;
}

.title {
  font-family: Source Sans Pro;
  font-size: 35px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #0c0f26;
}

.subtitle {
  font-family: Source Sans Pro;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: #0c0f26;

}

.rowContainer {
  border: solid 1px #dbdbdb;
  border-radius: 9px;
  cursor: pointer;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

  .arrowIcon {
    margin-top: 50px;
  }
}

.createServiceContainer {
  .checkBox {
    margin-top: 20px;
  }
  .inputGroup {
    margin-top: 20px;
    label {
      font-weight: 700;
    }
    input {
      outline: 0;
      border-width: 0 0 2px;
      border-color: #dbdbdb;
      margin-top: 10px;
      width: 100%;
    }
    [required]::-webkit-input-placeholder {
        color:    #f00;
    }
    textarea {
      outline: 0;
      border-width: 1px 1px 2px;
      border-color: #dbdbdb;
      margin-top: 10px;
      width: 100%;
      resize: vertical;
      min-height: 50px;
    }
  }
  .tileContainer {
    width: 100%;
    background: #f1f2f3;
    .tile {
      padding: 20px;
    }
  }
  .underline {
    margin-top: 40px;
    margin-bottom: 20px;
    border-bottom: 2px solid #dbdbdb;
  }

  .logoContainer {
    position: relative;
    width: 100px;
    height: 100px;
    margin-top: 10px;
    .logoCircle {
      width: 64px;
      height: 64px;
      line-height: 64px;
      background-color: #dbdbdb;
      border: solid 1px #dbdbdb;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }
    .logoCircleImg {
      width: 64px;
      height: 64px;
      line-height: 64px;
      background-color: white;
      border: solid 1px white;
      text-align: center;
      position: absolute;
      cursor: pointer;
    }
    .addButton {
      width: 24px;
      height: 24px;
      border-radius: 12px;
      border: solid 1px #1C4D82;
      line-height: 24px;
      text-align: center;
      background-color: #1C4D82;
      position: absolute;
      z-index: 0;
      color: white;
      top: 40px;
      left: 60px;
      cursor: pointer;
    }

    .fileInput {
      display: none;
    }
  }
  .urlPreview {
    border-radius: 7px;
    border: solid 1px #19aaf8;
    width: 100%;
    height: 98%;
    div {
      height: 100%;
      overflow: hidden;
      position: relative;
    }

    div iframe {
      height: 80%;
      left: 10%;
      position: absolute;
      top: 10%;
      width: 80%;
    }
  }

  .previewContainer {
    background-color: #f1f2f3;
    border: 1px solid #dbdbdb;
    height: 250px;
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 75%;
    position: relative;

    .footer {
      font-family: Source Sans Pro;
      font-size: 18px;
      font-weight: 400;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #1C4D82;
      position: absolute;
      bottom: 20%;
      left: 50%;
      transform: translate(-50%, 0);
      cursor: pointer;
    }
  }

  .editor {
    min-height: 462px;
  }
}

.tableLineHeight {
  padding-top: 7px;
  line-height: 1.5em;
}

.selectionHeader {
  h2 {
    font-size: 42px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.24;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  h3 {
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.42;
    letter-spacing: normal;
    text-align: center;
    color: #222222;
  }
  margin-top: 40px;
  text-align: center;
}

// Footer
.footer {
  z-index: 1;
  position: fixed;
  left: 0px;
  bottom: 0px;
  min-height: 84px; // + 36px
  width: 100%;
  background-color: white;
  box-shadow: 0 -1px 0 0 #DBDBDB;

  .footerContent {
    margin-left: 15px;
    margin-right: 15px;

    .selections {
      display: inline-flex;
      flex-wrap: wrap;
    }

    .frame {
      margin-right: 10px;
    }

    .buttons {
      display: flex;
      float: right;
    
      .simple { 
        box-sizing: border-box;
        min-height: 1px;
        position: relative;
        padding-right: 15px;
        width: 255px;
        max-width: 255px;
        // flex: 1 0 0px;
        margin-left: 0%;
        right: auto;
        left: auto;
      }
    }
  }
}

.editPencil {
  cursor: pointer;
  margin-bottom: 20px;
  padding-left: 10px;
  display: inline-flex;
}

.container {
  margin-bottom: 50px;
}

.logoImg {
  width: 64px;
  height: 64px;
  background-color: white;
}

.darkLogoImg {
  width: 64px;
  height: 64px;
  background-color: #1C4D82;
}