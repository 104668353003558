.checkboxContainer {
  position: relative;
  margin-top: 6px;
  padding-left: 35px;
  margin-bottom: 6px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: Source Sans Pro;
  font-size: 18px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  display: none;
  visibility: hidden;
  position: absolute;
  opacity: 0;
  left: -9999px;
}

.checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #FFFFFF;
  border: solid 1px #1C4D82;
  border-radius: 3px;
}

/* On mouse-over, add a grey background color */
.checkboxContainer:hover input ~ .checkmark {
  background-color: #F1F2F3;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked ~ .checkmark {
  background-color: #1C4D82;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkmark:after {
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.description {
  color: #666;
  font-family: Source Sans Pro;
  font-size: 18px;
  margin-left: 6px;
}