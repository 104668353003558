.mapContainer {
  width: 100%;
  height: 100%;
  background-color: #F6F6F4;

  .map {
    width: 100%;
    height: 100%;
  }
}

.modeButton {
  position: absolute;
  top: 14px;
  right: 14px;
}